.slider {
    height: fit-content;
    width: 100%;
    place-items: center;
    position: relative;
    
  
    .slide_track {
      width: calc(566px * 10);
      display: flex;
      animation: scroll1 40s linear infinite;
      background: transparent;
  
      .slide {
        width: 566px;
        height: auto;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
    @keyframes scroll1 {
      0% {
        transform: translate(0);
      }
      100% {
        transform: translate(-566px * 5);
      }
    }
    &::after,
    &::before {
      opacity: 0.4;
      height: 100%;
      position: absolute;
      width: 20%;
      z-index: 4;
    }
    &::before {
      left: 0;
      top: 0;
    }
    &::before {
      right: 0;
      top: 0;
    }
    @media screen and (max-width: 834px) {
      height: 123px;
      .slide_track {
        width: calc(470px * 10);
      }
      @media screen and (max-width: 430px) {
        .slide_track {
          width: calc(366px * 10);
        }
      }
    }
  }
  
  .slider2 {
    height: 147px;
    width: 100%;
    place-items: center;
    position: relative;
  
    .slide_track2 {
      width: calc(566px * 10);
      display: flex;
      animation: scroll2 40s infinite alternate;
  
      .slide2 {
        width: 566px;
        height: auto;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
    @keyframes scroll2 {
      0% {
        transform: translate(0);
      }
      100% {
        transform: translate(-566px * 5);
      }
    }
    &::after,
    &::before {
      opacity: 0.4;
      height: 100%;
      position: absolute;
      width: 20%;
      z-index: 4;
    }
    &::before {
      left: 0;
      top: 0;
    }
    &::before {
      right: 0;
      top: 0;
    }
    @media screen and (max-width: 834px) {
      height: 123px;
      .slide_track2 {
        width: calc(470px * 10);
      }
      @media screen and (max-width: 430px) {
        .slide_track2 {
          width: calc(366px * 10);
        }
      }
    }
  }
  