.product {
  z-index: 1;
  cursor: pointer;
  .add-btn {
    opacity: 0;
  }
  &:hover {
    // border: 2px solid gray;
    .add-btn {
      display: block;
      opacity: 1;
    }
  }
}

.slick-slider {
  margin: 0 -15px;
}
.slick-slide {
  padding: 10px;
}
