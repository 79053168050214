.slider {
  box-shadow: 0px 40px 106px -40px rgba(0, 0, 0, 0.42);
  -webkit-box-shadow: 0px 40px 106px -40px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 40px 106px -40px rgba(0, 0, 0, 0.42);

  .barner-slide {
    width: 100%;
    height: 100%;
    @media screen and (max-width: 780px) {
      max-width: 680px;
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      max-width: 450px;
    }
    @media screen and (max-width: 500px) {
      max-width: 450px;
    }
    @media screen and (max-width: 400px) {
     max-width: 350px;
    }
  }
}
.slide1 {
  background-image: url(../../Assets/slide1.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0 1rem;
}
.slide2 {
  background-image: url(../../Assets/slide2.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0 1rem;
}
.slide3 {
  background-image: url(../../Assets/slide3.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0 1rem;
}
.slide4 {
  background-image: url(../../Assets/slide4.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0 1rem;
}
.slide5 {
  background-image: url(../../Assets/slide5.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  // padding: 0 1rem;
}
.flyer {
  .explore {
    display: none;
  }
  &:hover {
    .explore {
      display: flex;
      animation: explorer 0.8s;
    }
  }
}
@keyframes explorer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.slick-slider {
  margin: 0;
}
.slick-slide {
  padding: 0;
}