/* eslint-disable-next-line */
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  padding: 0;
  margin: 0;
  box-sizing: 0;
  font-family: "Poppins", sans-serif;
}
:root {
  --red: #ff0018;
  --dark: rgba(23, 23, 25, 1);
}
.primary_gradient {
  background: #ff0018;
  background: linear-gradient(
    0deg,
    rgba(255, 0, 24, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
.garient2 {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  background: rgb(22, 22, 24);
  background: linear-gradient(
    90deg,
    rgba(22, 22, 24, 1) 56%,
    rgba(23, 23, 25, 1) 100%
  );
}
.red_shadow {
  box-shadow: 0px 15px 30px #ff00192a;
  transition: all;
  transition-duration: 0.5s;
}
.red {
  color: #ff0019;
}
.dark_shadow {
  box-shadow: 0px 15px 30px rgba(20, 20, 20, 0.233);
  transition: all;
  transition-duration: 0.5s;
}
.insta {
  color: white;
  border-radius: 0.55rem;
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.sm_shadow {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}
/* .success-animation { margin:150px auto;} */

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

.error-animation {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ui-error {
  width: 100px;
  height: 100px;
  margin: 40px;
  /* // border:1px solid #eee;/ */
}

.ui-error {
  &-circle {
    stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
    stroke-dashoffset: 260.75219024795285px;
    animation: ani-error-circle 1.2s linear;
  }
  &-line1 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.15s 1.2s linear both;
  }
  &-line2 {
    stroke-dasharray: 54px 55px;
    stroke-dashoffset: 55px;
    stroke-linecap: round;
    animation: ani-error-line 0.2s 0.9s linear both;
  }
}

@keyframes ani-error-line {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes ani-error-circle {
  0% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: 0;
  }
  35% {
    stroke-dasharray: 120px, 120px;
    stroke-dashoffset: -120px;
  }
  70% {
    stroke-dasharray: 0, 260.75219024795285px;
    stroke-dashoffset: -260.75219024795285px;
  }
  100% {
    stroke-dasharray: 260.75219024795285px, 0;
    stroke-dashoffset: -260.75219024795285px;
  }
}
